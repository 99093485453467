import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { setAccessToken, setUser } from 'redux/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const useAuthentication = (): { isLoading: boolean; accessToken: string | null; permissions: string[] | undefined } => {
  const { accessToken } = useAppSelector((state) => state.app);
  const [permissions, setPermissions] = useState();
  const dispatch = useAppDispatch();
  const { isAuthenticated, loginWithRedirect, isLoading, user, getAccessTokenSilently } = useAuth0();

  // Check if User is logged in, if not, redirect them to login screen
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { targetUrl: '/' } });
    }
    if (isAuthenticated && accessToken === null) {
      getAccessTokenSilently().then((accessToken) => dispatch(setAccessToken(accessToken)));
    }
  }, [accessToken, dispatch, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
    if (accessToken) {
      const payload = JSON.parse(window.atob(accessToken.split('.')[1]));
      const permissionsPayload = payload.permissions.filter((permission: string) => permission.split(':')[0] === 'useApplication' || permission.split(':')[0] === 'useApplciation');

      setPermissions(permissionsPayload);
    }
  }, [dispatch, user, accessToken]);

  return { isLoading, accessToken, permissions };
};
